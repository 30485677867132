#root ~ iframe{
  pointer-events: none;
}
.home-landing{
  background: url('../img/bg.png') right top / 55% 85% no-repeat;
  position: relative;
  z-index: 1;
}
.home-landing::before{
  content: '';
  position: absolute;
  inset: 0;
  opacity: 0.4;
  pointer-events: none;
  mix-blend-mode: overlay;
  background: url('../img/burbujas.png') center / cover no-repeat;
}
.logo-landing {
  max-width: 180px;
  margin: 0 auto;
}
.caption-landing h2 {
  font-size: 46px;
  line-height: 1.2;
}

.caption-landing h2 b {
  color: #e72929;
  text-transform: uppercase;
  font-weight: 900;
}

.caption-landing p {
  font-size: 20px;
  line-height: 1.6;
}
.title-landing {
  font-size: 60px!important;
  font-weight: 900!important;
}
.btn-landing {
  background: #e72929!important;
  font-weight: 900!important;
}
@media(max-width: 1199px){
  .home-landing{
    background: none;
  }
}

.modal-landing .content-modal-landing {
  max-width: 720px;
  width: 100%;
  margin: auto;
}

.modal-landing {
  display: flex;
  flex-direction: column;
}

.iframe-wrapper {
  position: relative;
  padding-top: 60%;
}

.iframe-wrapper iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
}
.modal-landing .content-modal-landing {
  max-width: 720px;
  width: 100%;
  margin: auto;
  background: #fff;
  border-radius: 10px;
}

.modal-landing {
  display: flex;
  flex-direction: column;
}

.iframe-wrapper {
  position: relative;
  padding-top: 60%;
}

.iframe-wrapper iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
  border: none;
}

span.close-modal {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 15px;
  cursor: pointer;
  padding: 6px;
  opacity: 0.6;
}

.header-modal-landing {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  padding: 15px 20px;
}

.header-modal-landing h2 {
  font-size: 22px;
  margin: 0;
  line-height: 1.2;
}

span.close-modal svg {
  display: block;
}
@media(max-width: 899px){
  .header-modal-landing h2{
    font-size: 18px;
  }
  .title-landing {
    font-size: 35px!important;
  }
  .logo-landing {
    max-width: 100px;
  }
  .phone-landing{
    max-width: 800px;
  }
  .caption-landing h2 {
    font-size: 34px;
    line-height: 1.2;
  }
  .caption-landing p {
    font-size: 16px;
    line-height: 1.6;
  }
}
.btn-instruccion {
  background: transparent!important;
  box-shadow: none!important;
  color: #e72929!important;
  font-weight: 600!important;
  text-decoration: underline!important;
  line-height: 1!important;
  padding: 10px!important;
}

.instrucciones-landing h3 {
  margin-bottom: 10px;
}